import React, { useRef, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Form, Input, Button, Row, Checkbox, Col, Typography } from "antd";
import Cookies from "js-cookie";
import axios from "axios";

import chevron from "../../images/svg/chevron_left.svg";

const { Title } = Typography;

const LpFormShort = (props) => {
  const { leadType, subHeader, formId, thankYouPath } = props;
  const form = useRef(null);
  const [loading, setLoading] = useState(false);
  const [textMessages, setTextMessages] = useState(false);
  const emailValidation = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  const phoneValidation = new RegExp(
    /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im
  );

  const onTextMessagesToggle = useCallback(() =>
    setTextMessages(() => !textMessages)
  );

  const onFinish = (values) => {
    setLoading(true);
    const formData = {
      fields: [
        {
          name: "firstname",
          value: values.name,
        },
        {
          name: "email",
          value: values.email,
        },
        {
          name: "phone",
          value: values.phone,
        },
        {
          name: "company",
          value: values.company,
        },
        {
          name: "text_messages",
          value: textMessages,
        },
        {
          name: "gclid",
          value: Cookies.get("gclid") || "",
        },
        {
          name: "utm_source",
          value: Cookies.get("utmSource") || "organic",
        },
        {
          name: "utm_medium",
          value: Cookies.get("utmMedium") || "",
        },
        {
          name: "utm_campaign",
          value: Cookies.get("utmCampaign") || "",
        },
        {
          name: "utm_content",
          value: Cookies.get("utmContent") || "",
        },
        {
          name: "utm_term",
          value: Cookies.get("utmTerm") || "",
        },
      ],
      context: {
        hutk: Cookies.get("hubspotutk") || undefined,
        pageUri: window.location.href,
        pageName: document.title,
      },
    };
    axios({
      method: "post",
      url: `https://api.hsforms.com/submissions/v3/integration/submit/2970821/${formId}`,
      headers: { "Content-Type": "application/json" },
      data: JSON.stringify(formData),
    })
      .then(() => {
        if (window.sessionStorage.getItem("Name")) {
          window.sessionStorage.removeItem("Name", values.name);
          window.sessionStorage.setItem("Name", values.name);
        } else {
          window.sessionStorage.setItem("Name", values.name);
        }
        if (typeof window !== `undefined` && typeof dataLayer !== `undefined`) {
          // eslint-disable-next-line no-undef
          dataLayer.push({ event: `${leadType}-lead` });
        }
      })
      .then(() => {
        window.location.pathname = thankYouPath;
      })
      // eslint-disable-next-line consistent-return
      .catch((error) => {
        if (error.response) {
          if (
            error.response.data.errors[0].errorType.toString() ===
            "INVALID_EMAIL"
          ) {
            // eslint-disable-next-line no-alert
            return alert(
              `Looks like the email ${values.email} can not be validated.
                Please use a valid email.`
            );
          }
        } else if (error.request) {
          // eslint-disable-next-line no-console
          console.log(error.request);
        } else {
          // eslint-disable-next-line no-console
          console.log("Error", error.message);
        }
      });
  };
  return (
    <section className="lead-form-wrapper" ref={form} style={{ marginTop: 40 }}>
      <form
        name="Restaurant LP Demo"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        hidden
      >
        <input type="text" name="name" />
        <input type="text" name="email" />
        <input type="text" name="company" />
        <input type="text" name="phone" />
        <input type="text" name="textMessages" />
      </form>
      <Row className="header_wrapper">
        <Col xs={24}>
          <Title level={1}>
            Get your free demo
            <span className="blue_dot">.</span>
          </Title>
        </Col>
        <Col xs={24}>
          <p>{subHeader}</p>
        </Col>
      </Row>

      <Form
        name="Lead Form"
        className="lead-form"
        layout="vertical"
        initialValues={{
          remember: false,
        }}
        onFinish={onFinish}
      >
        {/* Name */}
        <input type="hidden" name="Learn more" value="contact" />

        <Form.Item
          label="Your Name"
          name="name"
          rules={[
            {
              required: true,
              message: "Please enter your name",
              min: 2,
            },
          ]}
        >
          <Input size="large" placeholder="Your Name" />
        </Form.Item>

        {/* Email */}

        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Please enter a valid email",
              pattern: emailValidation,
            },
          ]}
        >
          <Input size="large" placeholder="e.g. yourname@gmail.com" />
        </Form.Item>

        {/* Business Name */}
        <Form.Item
          label="Business name"
          name="company"
          rules={[
            {
              required: true,
              message: "Please enter your business name",
              min: 3,
            },
          ]}
        >
          <Input size="large" placeholder="e.g. MyBusiness LLC " />
        </Form.Item>

        {/* Phone */}

        <Form.Item
          label="Phone number"
          name="phone"
          rules={[
            {
              required: true,
              message: "Please enter your phone",
              pattern: phoneValidation,
            },
          ]}
        >
          <Input size="large" placeholder="e.g. (555) 555-1243 " />
        </Form.Item>
        <Form.Item name="textMessages">
          <Checkbox checked={textMessages} onChange={onTextMessagesToggle}>
            I agree to receive text message updates
          </Checkbox>
        </Form.Item>
        {/* Submit */}
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="cta-primary"
            style={{ marginTop: 20 }}
            loading={loading}
            size="small"
          >
            Get Started
            <img
              src={chevron}
              alt="chevron icon"
              style={{ margin: "5px 5px 2px 10px" }}
            />
          </Button>
        </Form.Item>
      </Form>
    </section>
  );
};

LpFormShort.propTypes = {
  formId: PropTypes.string.isRequired,
  leadType: PropTypes.string.isRequired,
  subHeader: PropTypes.string.isRequired,
};

export default LpFormShort;
